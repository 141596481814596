*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    justify-content: center;
    align-items: center;
    color: white;
}
/* .................HOME...................... */
section{
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100vh;
    background-image: url(../images/cbt.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-color: rgba(17, 12, 153, 0.7);
    background-blend-mode: multiply;
}

.all{
    border: 3px solid rgb(238, 177, 177);
    width: 300px;
    background-color: rgb(2, 2, 69);
    height: auto;
    justify-content: center;
    align-items: center;
    display: flex;
} 
.username .inputbox{
    padding: 15px 0px;
    color: white;
}
.label1{
    font-size: 18px; 
}
button{
   width: 50%;
   padding: 5px;
   font-size: 15px;
   font-weight: 900;
   border-radius: 5px;
   border: 2px solid rgb(247, 247, 252);
   background-color: turquoise;
   color: white;
   outline: none;
}
button:hover{
    background-color:rgb(203, 15, 15);
    color: white;
    border: transparent;
}
button:active{
    background-color:red;
}
input{
    background-color: rgb(238, 177, 177);
    padding: 5px;
    width: 100;
    font-weight: 900;
    border: transparent;
}
.cbt{
    color: white;
    text-align: center;
    font-size: 25px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: 5px;
    
}


/* ALL SUBJECT.......................... */
.container{
    height: 100vh;
    width: 100vw;    
    justify-content: center;
    align-items: center;
    display: flex; 
    flex-direction: column;
    background-color: rgb(231, 231, 237);
    
}
.content{
    color: white;
    justify-content: center;
    align-items: center;
    /* display: flex;  */
    text-align: center;
    width:90%;
    height: 10%;
    background-color: rgb(7, 7, 111);
}
.icon{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: white;
    background-color: green;
    font-size: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding-top: 5px;
}
.exam{
    display: block;
    font-size: 35px;
    font-family: 'Times New Roman', Times, serif;
}
.content h4{
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(238, 177, 177);

}
label .labelsubject{
    color: rgb(255, 255, 255);
    padding-top: 0px ;
    font-family: Arial, Helvetica, sans-serif;
}
.allsubjects{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    gap: 50px;
    
}
.subjects{
    padding: 50px 20px;
    width: 250px;
}
.button1 button{
    color:white;
    width: 100%;
    padding: 10px 20px;
    border: 3px solid blue;
    background-color: blue;
}
.button1 button:hover{
    background-color: rgb(7, 7, 111);
    border: transparent;
}

/* DESCRIPTION....................... */
.container1{
    background-color: rgb(231, 231, 237);
    height: 100vh;
    /* margin: auto; */
}
.header{
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    flex-direction: column;
    padding: 20px; 
    font-size: 20px;
}
.header h4{
    padding: 10px;
}
main{
    font-size: 30px;
    margin: 2%;
}
.container1 .button1{
    width: 20%;
    background-color: green;
    color: white;
}
.des button{
    padding: 10px 20px;
    border: 3px solid blue;
    background-color: rgb(2, 2, 209);
    width: 30%;
}
/* EXAM........................ */
    .allexam{ 
    width: 80%; 
    border: 1px solid black; 
    margin: auto;
}  
.examquestion{
    padding-left: 10%;
    
}
.nav1 #user{
    font-size: 22px;
}
.nav1 .cont{
    font-size: 25px;
}
label .la{
    color: red;
}
.submit{
    padding: 10px 20px;
    border: 3px solid blue;
    background-color: rgb(2, 2, 209);
    width: 30vw;
    margin: 5px;
}

.lab{
    padding-right:2%; 
}
.all-label{
    padding-left: 3%;
}
/* /*---------------------score---------------------------- */


.nav1{
    width: 100%;
    height: 6vh;
    display: flex;
    background-color:rgb(221, 210, 210);
    padding: 4px 0px;
    gap:10%; 
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    justify-content: space-between;
}
.nav1 p{
    font-size: 180%;
    color: rgb(112, 112, 219);
    margin-top: -4px;
}
.nav1 span{
    color: black;
    font-size: 80%;
}
.nav1 .score{
    color: black;
    background-color: rgb(112, 112, 219);
    width: 10%;
    border: none; 
    border-radius: 0px;
}
.rest{
    background-color: red;
    width: 12%;
    height: 5vh;
    border: none;
    border-radius:0px;

}
 details .tableup{
    width:50%;
    height: 20vh;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 120%;
    margin: 1%;

}
details table .data{
    background-color:rgb(221, 210, 210) ;
}
.abl{
    color: blue;
    margin: 1%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
details .tabledown{
    width: 50%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 120%;
    margin: 1%;

}
details summary{
    background-color:rgb(221, 210, 210) ;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 120%;

}
.ta{
    font-weight: bold;
    color: blue;
}
.dat1{
    color:blue ;
}
.logout{
    width: 100%;
    background-color: red;
}

section{
    animation-name: cbt;
    animation-duration: 40s;
    animation-iteration-count: infinite;
    animation-delay: 2s;
    animation-timing-function: step-start;
}
@keyframes cbt {
    0%{
        background: url(../images/cbt.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        background-color: rgba(7, 5, 59, 0.7);
        background-blend-mode: multiply;
    }
    25%{
        background: url(../images/cbt5.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        background-color: rgba(7, 5, 59, 0.7);
        background-blend-mode: multiply;
    }
    50%{
        background: url(../images/cbt2.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        background-color: rgba(3, 2, 38, 0.7);
        background-blend-mode: multiply;
    }
    100%{
        background: url(../images/cbt4.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        background-color: rgba(3, 2, 42, 0.7);
        background-blend-mode: multiply;
    }
    
}